/*@tailwind base;*/
/*@tailwind components;*/
/*@tailwind utilities;*/

@font-face {
  font-family: 'd-din_condenseddinCnBd';
  src: url('../public/fonts/d-dincondensed-bold-webfont.woff2') format('woff2'),
       url('../public/fonts/d-dincondensed-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.title-font{
  font-family: 'd-din_condenseddinCnBd', sans-serif;
}
.subtitle-font {
  font-family: 'Roboto', sans-serif;
  font-weight:700;
}
.medium-font {
  font-family: 'Roboto', sans-serif;
  font-weight:500;
}
.regular-font {
  font-family: 'Roboto', sans-serif;
  font-weight:400;
}

.white-text { color: #ffffff; }
.red-text {color:#b93838;}
.hidden {display:none;}
#chart_div div div div svg g g g circle {stroke: #05a9ca;stroke-opacity: 0.8;}

/* Desktop Nav */
.navbar-row {padding-top:0;padding-bottom:0;padding-right:0;background-color:#2e2043;height:75px;}
.navbar-row .navbar {height:100%;padding:0;}
.navbar-row .navbar .navbar-brand .nav-brand-img {max-width:150px;}
.navbar-row .navbar #los-nav {height:100%;}
.navbar-row .navbar #los-nav .navbar-brand {margin-left:7%;}
/*.navbar-row .navbar #los-nav a {margin-left:7%;max-width:185px}*/
.navbar-row .navbar #los-nav a img {max-height:100%;max-width:100%;height:35px;}
.navbar-row .navbar #los-nav .navbar-nav {height:100%;}
.navbar-row .navbar #los-nav .navbar-nav .nav-item {display:flex;align-items:center;justify-content:center;padding:0 20px;background-image:url(/img/nav-shadow.png);background-repeat: no-repeat;}
.navbar-row .navbar #los-nav .navbar-nav .nav-item .nav-link {font-size: 13px;font-weight: 500;letter-spacing: 3px;color: #ffffff;text-transform: uppercase;}
.navbar-row .navbar #los-nav .navbar-nav .nav-item:hover {background-color: #353535;}
.navbar-row .navbar #los-nav .navbar-nav .login-link {width:150px;display:flex;align-items:center;justify-content:center;background: #353535 url(/img/nav-shadow.png);}
.navbar-row .navbar #los-nav .navbar-nav .login-link a img {width:20%;}
.navbar-row .navbar #los-nav .navbar-nav .login-link a span {font-size: 13px;font-weight: 500;letter-spacing: 3px;color: #ffffff;text-transform: uppercase;}
.navbar-row .navbar #los-nav .navbar-nav .login-link a:hover {background: #434343 url(/img/nav-shadow.png);}
.navbar-row .navbar #los-nav .navbar-nav .active {background: #353535 url(/img/nav-shadow.png);background-repeat: no-repeat;}

/* Mobile Nav */
#sidebar-toggle {z-index: 9999;position: absolute;top: 20px;right: 20px;width: 24px;height: 20px;cursor: pointer;}
#sidebar-toggle span {display: block;position: absolute;height: 4px;width: 100%;background-color:#fff;border-radius: 1px;right: 0;}
#sidebar-toggle span:nth-child(2) {top: 10px;}
#sidebar-toggle span:nth-child(3) {top: 20px;}
.mobile-nav {margin-top:15px;}
.mobile-nav-items {list-style:none;padding:0;}
.mobile-nav-items li {padding:20px;border-bottom:1px solid #fff;}
.mobile-nav-items li a {color:#fff;}
.modal-content {margin-top:2%;}
.modal-body {background-color:#000;}
.modal-dialog {max-width:1000px;}
.modal-backdrop.show {opacity:0.9;}
#sidebar-toggle span {z-index:9999;display: block;position: absolute;height: 4px;width: 100%;background-color: #fff;border-radius: 1px;right: 0;}

/* Home Page */
.banner-row {margin-bottom:200px;}
.banner-row .banner-col {padding-top: 30px;height:580px;background-repeat: no-repeat;background-position:center;background-size:cover;}
.banner-row .bottom-banner-col {background-color:#05a9ca;margin-top:-85px;}
.banner-row .banner-col .container {height:100%;}
.banner-row .banner-col .container .banner-content-row {height:100%;}
.banner-row .banner-col .container .banner-content-row div .blocks-img {max-width:57%;}
.banner-row .banner-col .container .banner-content-row .col-12 .row .banner-video-content-col {padding:0px;}
.banner-row .banner-col .container .banner-content-row .col-12 .row .banner-video-content-col .banner-video-text {font-family: 'd-din_condenseddinCnBd', sans-serif;font-size:35px;line-height:38px;color: #ffffff;text-transform: uppercase;}
.banner-row .banner-col .container .banner-content-row .col-12 .row .banner-video-content-col .row .col-1 {padding-right:0px;padding-left:0px;margin-left:15px;}
.banner-row .banner-col .container .banner-content-row .col-12 .row .banner-video-content-col .row .col-1 img {max-width:100%;max-height:60%;}
.banner-row .banner-col .container .banner-content-row .col-12 .row .banner-video-content-col .row .col-6 {padding-left:0px;padding-right:25px;}
.banner-row .banner-col .container .banner-content-row .col-12 .row .banner-video-content-col .row .col-6 p {text-transform:uppercase;color:#fff;font-size:17px;font-weight:600;line-height:20px;color:#ffffff;margin-bottom: 25px;}
.banner-row .banner-col .container .banner-content-row .col-12 .row .banner-video-content-col .row .col-4 {padding-left:35px;}
.banner-video-video-col .banner-video-thumb {margin-left:-15px;cursor:pointer;width:100%;margin-top:-24px;}
.banner-row .banner-col .container .banner-content-row .banner-content-col {display:flex;align-items:center;}
.banner-row .banner-col .container .banner-content-row .banner-content-col .content-container {padding-top:15px;}
.banner-row .banner-col .container .banner-content-row .banner-content-col .content-container h4 {font-size: 78px;line-height: 78px;color: #ffffff;text-transform: uppercase;}
.banner-row .banner-col .container .banner-content-row .banner-content-col .content-container p {font-size: 22px;font-weight: 400;line-height: 34px;color: #ffffff;margin-bottom:25px;}
.banner-wave-row {margin-bottom:75px;}
.banner-row .banner-bottom-row {background-color:#0091AE;height:185px;}
.banner-row .banner-bottom-row img {position: absolute;bottom: 0;left: 0;max-width: 100%;}
.white-hr-top {border-top:2px solid #fff;margin-bottom:15px;}
.white-hr-bottom {border-top:2px solid #fff;margin-top:15px;}
.mobile-homepage-banner-col {padding-top:0;}
.mobile-banner-video-content-col {padding-top:25px;}
.mobile-banner-video-text {text-align:center;color:#fff;}
.mobile-banner-video-col {padding-top:25px;}
.mobile-research-paper-img {max-width:70%;max-height:70%;}
.mobile-research-paper-title-col {color:#fff;}

.pad-100 {padding-top:100px !important;}
.contents-row  .contents-col {padding-bottom:100px}
.contents-row  .contents-col .container .title-row {margin-bottom:25px;}
.contents-row  .contents-col .container .title-row  .title-col h2 {font-size: 78px;color:#2e2043;;text-transform: uppercase;}
.contents-row  .contents-col .container .content-row .content-row-text {padding-top:70px;display:flex;align-items:center;}
.contents-row  .contents-col .container .content-row .content-row-text .content .title {width:100%;font-size: 50px;line-height: 50px;color:#b93838;text-transform: uppercase;}
.contents-row  .contents-col .container .content-row .content-row-text .content.subtitle {font-size: 24px;line-height: 24px;font-weight: 700;color: #434343;text-transform: uppercase;}
.contents-row  .contents-col .container .content-row .content-row-text .content .text {font-size: 18px;font-weight: 400;line-height: 34px;color: #434343;}
.contents-row  .contents-col .container .content-row .content-row-img img {max-width:100%;}

.health-index-row {padding-bottom:50px;}
.health-index-row .health-index-image-col img {width:100%;max-width:100%;}
.health-index-row .health-index-text-col .title {width:100%;font-size: 50px;line-height: 50px;color:#b93838;text-transform: uppercase;}
.health-index-row .health-index-text-col .text {font-size: 18px;font-weight: 400;line-height: 34px;color: #434343;}

/* Banner */
.page-banner-col {width:100%; padding:0;margin-bottom:50px;}
.pages-banner {margin:0;width:100%;min-height:25vh;background-repeat: no-repeat;background-position:center;background-size:cover;}
.pages-banner .banner-col {min-height:60vh;}
.pages-banner .container img {position: absolute;bottom: 0;left: 0;max-width: 100%;}
.pages-banner .banner-col .container {display:flex;align-items:center;}
.pages-banner .banner-col .container .row {width:100%;}
.pages-banner .banner-col .banner-title {font-size: 78px;line-height: 78px;color: #ffffff;text-transform: uppercase;}
.pages-banner .banner-col .banner-title-with-sub {text-align:center;margin-bottom:0px;font-family: 'd-din_condenseddinCnBd', sans-serif;font-size:72px;line-height:60px;color: #ffffff;text-transform: uppercase;}
.pages-banner .banner-col .banner-sub-title {font-size:36px;}
.inner-page-banner-row {width:85%;}
/*.mobile-pages-banner {min-height:70vh;}*/
/*.mobile-pages-banner .banner-col {height:55.4vh;}*/
.mobile-pages-banner {background:#05a9ca;}
.mobile-pages-banner .container img {position: absolute;bottom: 0;left: 0;max-width: 100%;}
.mobile-pages-banner .banner-col .container {display:flex;align-items:center;}
.mobile-pages-banner .banner-col .container .row {width:100%;}
.mobile-pages-banner .banner-col .banner-title {font-size: 78px;line-height: 78px;color: #ffffff;text-transform: uppercase;}

.mobile-pages-banner-row {background:#05a9ca;}

/* About Page */
.about-content-row .about-content-col .container .about-content-container-row {padding:50px 0;}
.about-content-row .about-content-col .container .about-content-container-row .title-col {margin-bottom:50px;}
.about-content-row .about-content-col .container .about-content-container-row .title-col h2 {font-size: 50px;line-height: 50px;color: #353535;text-transform: uppercase;margin-bottom:15px;}
.about-content-row .about-content-col .container .about-content-container-row .title-col p {font-size: 24px;line-height: 24px;font-weight: 700;color:#b93838;text-transform: uppercase;}
.about-content-row .about-content-col .container .about-content-container-row .text-col p {font-size: 18px;font-weight: 400;line-height: 34px;color: #353535;}
.about-content-row .about-content-col .container .about-content-container-row .img-col img {max-width:100%; margin-left:auto;margin-right:auto;}

/* Blog Page */
.blog-posts-container {padding:0 0 50px 0;}
.main-blog-row {}
.post-image-container {margin-bottom:25px;}
.post-image-container .post-image {max-width:100%;} 
.blog-post .title {font-size: 50px;line-height: 50px;color:#b93838;text-transform: uppercase;margin-bottom:10px;}
.blog-post .details {display:flex;align-items:center;font-size:18px;margin-bottom:25px;}
.blog-post .details .category {color:#b93838;}
.blog-post .details .fa {font-size:8px;margin:0 10px;}
.blog-post .banner img {max-width:100%;margin-left:auto;margin-right:auto;margin-bottom:25px;}
.blog-post .summary {font-size: 18px;font-weight: 400;line-height: 34px;color: #353535;}
.blog-post .read-more {margin:55px 0 15px 0;}
.blog-post .read-more a {color:#b93838;text-transform:uppercase;}
.featured-blog-post-col .featured-blog-post-list {padding-left:20px;}
.featured-blog-post-col .featured-blog-post-list .featured-blog-post-item {color:#b93838;}
.featured-blog-post-col .featured-blog-post-list .featured-blog-post-item a {color:#b93838;}
.blog-divider-img {max-width:100%;}

/* Blog Post */
.post-container .post-row .post-col .title {font-size: 50px;line-height: 50px;color: #b93838;text-transform: uppercase;margin-bottom: 10px;}
.post-container .post-row .post-col .body {overflow:hidden;font-size: 18px;font-weight: 400;line-height: 34px;color: #353535;}
/*.post-container .post-row .post-col .body table {height: 19px; background-color: #17bce6; border-color: #2e1e41; margin-left: auto; margin-right: auto;}*/
.post-container .post-row .post-col .details {display:flex;align-items:center;font-size:18px;margin-bottom:25px;}
.post-container .post-row .post-col .details .category {color:#b93838;}
.post-container .post-row .post-col .details .fa {font-size:8px;margin:0 10px;}
.post-container .post-row .post-col img {max-width:100%;margin-left:auto;margin-right:auto;margin-bottom:25px;}

/* Research Paper */
.rp-overview-row {margin-bottom:50px;}
.rp-overview-row .rp-overview-col {}
.rp-overview-row .rp-overview-col .overview-heading {font-size: 50px;line-height: 50px;color: #353535;text-transform: uppercase;margin-bottom: 15px;}
.rp-overview-row .rp-overview-col .overview-sub-heading {font-size: 24px;line-height: 24px;font-weight: 700;color: #b93838;text-transform: uppercase;margin-bottom:25px;}
.research-paper-row {}
.research-paper-row .research-paper-img-col img {max-width:100%;}
.research-paper-row .research-paper-details-col {}
.research-paper-row .research-paper-details-col .title {font-size: 50px;line-height: 50px;color:#b93838;text-transform: uppercase;margin-bottom:10px;}
.research-paper-row .research-paper-details-col .abstract {}
.research-paper-row .research-paper-details-col .download-btn {}

/* Application */
.data-point {display:flex;align-items:center;}
.data-point-img {max-width:100%;}
.data-point .content .title {width:100%;font-size: 50px;line-height: 50px;color:#b93838;text-transform: uppercase;margin-bottom:.5rem;}
.data-point .content .sub-title {font-size: 24px;line-height: 24px;font-weight: 700;color: #434343;text-transform: uppercase;margin-bottom:1rem;}
.data-point .content .text {font-size: 18px;font-weight: 400;line-height: 34px;color: #353535;}

/* Registration Form */
.error-message {display:none;}
.has-error {border:1px solid #ff0000;}
.registration-form-outer-row {padding:50px 15px;background-color:#05a9ca;}
.registration-form-group {margin-top:25px;}

/* Registration Page */
.paper-id {padding:100px 0;}

/* Science and Data Page */
.rp-col {margin-bottom:25px;}

/* Partners page */
.partner-office {background:#efefef;padding:15px;min-height:378px;}
.partner-office h2 {font-size:1.5rem;}
.partner-container {padding-bottom:100px;}

/*.partner-office a {color:#b93838;}*/
/*.partner-office a:hover {color:#d58787;text-decoration:none;}*/
.partner-name {margin-top:5px;margin-bottom:5px;font-size:14px;}
.partner-title {margin-top:5px;margin-bottom:5px;font-size:14px;}
.partner-description {margin-top:5px;margin-bottom:5px;font-size:14px;}
.partner-description div {margin-bottom:15px}
.partner-image-container .image-div {height:200px;width:150px;overflow:hidden;background:no-repeat center center;background-size:cover;}
.office-placeholder {background:#efefef;padding:15px;min-height:378px;padding:35px 25px 0 25px;}
.office-placeholder .placeholder-title {font-size:1.5rem;}
.office-placeholder .placeholder-title p {font-weight: bolder;margin-bottom:.5rem;}
.office-placeholder .placeholder-title p:last-of-type {margin-bottom:35px;}

/* Partner Page */
.partner-pages-banner {height:40vh;}
.partner-pages-banner .banner-col {height:40vh;}
.partner-pages-banner .container img {position: absolute;bottom: 0;left: 0;max-width: 100%;}
.partner-pages-banner .banner-col .container {display:flex;align-items:center;height:50%;}
.partner-pages-banner .banner-col .container .row {width:100%;}
.partner-pages-banner .banner-col .banner-title {font-size: 60px;line-height:64px;color: #ffffff;text-transform: uppercase;}
.partner-page .partner-description {margin-bottom:50px;font-size: 18px;font-weight: 400;line-height: 24px;color: #353535;font-family: 'Roboto', sans-serif;}
.partner-page .partner-location {width:100%;font-size:28px;line-height:32px;color:#353535;text-transform:uppercase;font-family: 'd-din_condenseddinCnBd', sans-serif;}
.partner-page .partner-name {width:100%;font-size:32px;line-height:36px;color:#353535;text-transform:uppercase;font-family: 'd-din_condenseddinCnBd', sans-serif;}
.partner-page .partner-title {width:100%;font-size:24px;line-height:28px;color:#b93838;text-transform:uppercase;font-family: 'd-din_condenseddinCnBd', sans-serif;}
.partner-details-container .partner-image-div {height:275px;width:200px;background-repeat:no-repeat;background-position: center center;background-size:cover;}

/* Vistage Chairs Page */
.execution-section {padding:50px 0;}
.vs-execution-text {font-size:26px;display:flex;align-items:center;}
.vs-execution-img img {max-width:100%;max-height:100%;}
.vs-section {background:#05a9ca;padding:50px 0;}
.vs-section .vs-section-content {color:#fff;}
.vs-section .vs-section-content .vs-title {font-size:3em;margin-bottom:25px;}
.vs-section .vs-section-content .vs-list ul li {font-size:1.5em;font-weight:600;}
.community-section {padding:100px 0;}
.community-text {font-size:26px;}
.community-img img {max-width:100%;max-height:100%;}
.vs-contact-content {color:#fff;}
.vs-contact-title {font-size:3em;margin-bottom:50px;}
.vs-contact-list ul li {font-size:1.5em;font-weight:600;}
.vs-contact-contact {font-size:1.5em;font-weight:600;}
.callout-color-bg {border-radius:25px;background-color:#f7bd4a;padding:25px 40px;color:#000;font-size:1.5em;margin:50px 0;}
.vs-email-link {color:#fff;}
.vs-email-link:hover {color:#2e2043;}
.pinnacle-chair {padding:50px 0;}
.pinnacle-chair .pinnacle-title {font-size:3em;margin-bottom:50px;}
.pinnacle-chair .pinnacle-list {font-size:1.2em;font-weight:600;}
.pinnacle-chair .pinnacle-list ul li {padding: 15px;}
.pinnacle-img img {max-width:100%;max-height:100%;}
.eos {background:#05a9ca;padding:50px 0;}
.eos .eos-title {font-size:3em;margin-bottom:50px;}
.eos .eos-list {font-size:1.2em;font-weight:600;}
.eos .eos-list ul li {padding: 15px;}
.eos-img img {max-width:100%;max-height:100%;}
.consultant {padding:50px 0;}
.consultant .consultant-title {font-size:3em;margin-bottom:50px;}
.consultant .consultant-list {font-size:1.2em;font-weight:600;}
.consultant .consultant-list ul li {padding: 15px;}
.consultant-img img {max-width:100%;max-height:100%;}
.contact {background:#05a9ca;padding:50px 0;}
/*.vistage-row {padding:50px 0 0 0;}*/

/* Contact Form */
.contact-form-row {padding:50px 15px;background-color:#05a9ca;}
.contact-form-container {padding:0 150px;}
.contact-form-container .contact-form-row .contact-form-col-1 {margin-bottom:25px;}
.contact-form-container .contact-form-row .contact-form-col-1 h4 {font-size: 50px;line-height: 50px;color: #ffffff;}
.contact-form-container .contact-form-row .contact-form-col-2 .contact-form-fields .contact-form-col .form-control {border:none;border-radius:0px !important;}
.contact-form-container .contact-form-row .contact-form-col-2 .contact-form-fields .contact-form-col input::placeholder {font-family: 'Roboto', sans-serif;font-size: 12px;font-weight: 700;letter-spacing: 1px;color: #757575;text-transform: uppercase;}
.contact-form-container .contact-form-row .contact-form-col-2 .contact-form-fields .contact-form-submit-col {margin-top:25px;}
.contact-button-container {margin-top:25px;display:flex;justify-content:flex-end;}

/* Footer */
.footer {min-height:100px;background:#2e2043;;color:#fff;padding-top:25px;padding-bottom:25px;}
.footer  .container .footer-row .footer-col p {margin-bottom:0;font-size: 14px;line-spacing: 20px;font-weight: 400;color: #ffffff;}
.footer  .container .footer-row .footer-col-2 p a {color:#fff;}
.footer  .container .footer-row .footer-col-3 .row .footer-icon-container {margin-bottom:15px;}
.footer  .container .footer-row .footer-col-3 .row .footer-icon-container .footer-icon {margin-right:10px;}
.footer  .container .footer-row .footer-col-3 .row .footer-icon-container .footer-icon img {width:20px;}
.footer  .container .footer-row .footer-col-3 .row .text-container {font-size: 10px;font-weight: 400;letter-spacing: 2px;color: #ffffff;text-transform: uppercase;}
.footer-privacy-link {color:#fff;}
.footer-privacy-link:hover {color:#fff;}

/* Modal video */
#video1 {width: 100%; height: auto; margin:0 auto; frameborder:0;}
.modal-backdrop {display:none !important;}

/* Elements */
.purple-btn {border-radius:75px;background-color:#2e2043;border:none;color:#fff !important;cursor:pointer;padding:10px 30px;letter-spacing: 3px;box-shadow: 0px 0px 12px rgba(0,0,0,.4);}
.purple-btn:hover {color:#fff;text-decoration:none;background-color: #6c627b;box-shadow: 0px 0px 0px rgba(0,0,0,.4);}
.sm-placer {font-size:30px;vertical-align:text-top;}
.sm-placer-no-size {vertical-align:text-top;}

/* */
.hideMenuNav {display:none;;z-index:999;position:relative}
.showMenuNav {display:block;min-width:45%;max-width:65%;float:right;position:relative;z-index: 9998;height:100vh;background:#2e2043;margin-right:-15px;}
.mobile-nav-act-link-container {display:flex;align-items:center;justify-content:flex-end;height:75px;}
.mobile-nav-act-link-container img {height:35px;cursor:pointer;}
/*.mobile-brand-container {height:100%;display:flex;align-items:center;}*/
.mobile-navbar-brand img {max-width:85%;padding-top:15px;}

@media (min-width: 992px) and (max-width:1199px) {
  .vs-mobile-sub-heading {font-size:32px !important;}
  .homepage-banner-col {height:200px !important;}
  .banner-row .banner-col {height:525px;}
  .banner-row .bottom-banner-col {background-color:#05a9ca;margin-top:0px;padding-bottom:25px;}
  .mobile-pages-banner .banner-col .container {display:block;align-items:start;}
  .banner-row .banner-col .container .banner-content-row .banner-content-col {display:block;align-items:start;margin-top:25px;}
  .mobile-pages-banner .container .banner-video-thumb {position:relative;max-width:100%;}
  .mobile-pages-banner .container .research-paper-img img {position:relative !important;max-width:100% !important;margin-top:5px;}
  .banner-row .banner-col .container .banner-content-row .banner-content-col .content-container h4 {font-size:65px;}
  .banner-row .banner-col .container .banner-content-row .banner-video-video-col {padding: 0px;}
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {line-height:17px;font-size:12px;}
}

@media (min-width: 768px) and (max-width:991px) {
  .vs-mobile-sub-heading {font-size:32px !important;}
  .banner-row .banner-col {height:200px;}
  .banner-row .bottom-banner-col {background-color:#05a9ca;margin-top:0px;padding-bottom:25px;}
  .mobile-pages-banner .banner-col .container {display:block;align-items:start;}
  .banner-row .banner-col .container .banner-content-row .banner-content-col {display:block;align-items:start;margin-top:25px;}
  .mobile-pages-banner .container .banner-video-thumb {position:relative;max-width:100%;margin-top:35px;}
  .mobile-pages-banner .container .research-paper-img img {position:relative !important;max-width:100% !important;margin-top:5px;}
  .banner-row .banner-col .container .banner-content-row .banner-content-col .content-container h4 {font-size:35px;}
  .banner-row .banner-col .container .banner-content-row .banner-video-video-col {padding: 0px;}
}

@media (min-width: 556px) and (max-width:767px) {
  .vs-mobile-sub-heading {font-size:32px !important;}
  .banner-row .banner-col {height:200px;}
  .banner-row .bottom-banner-col {background-color:#05a9ca;margin-top:0px;padding-bottom:25px;}
  .mobile-pages-banner .banner-col .container {display:block;align-items:start;}
  .banner-row .banner-col .container .banner-content-row .banner-content-col {display:block;align-items:start;margin-top:25px;}
  .mobile-pages-banner .container .banner-video-thumb {position:relative;max-width:140%;margin-top:30px;}
  .mobile-pages-banner .container .research-paper-img {padding:0px;}
  .mobile-pages-banner .container .research-paper-img img {position:relative !important;max-width:100% !important;margin-top:5px;}
  .banner-row .banner-col .container .banner-content-row .banner-content-col .content-container h4 {font-size:35px;}
  .banner-row .banner-col .container .banner-content-row .banner-video-video-col {padding: 0px;}
}

@media (max-width:555px) {
  .vs-mobile-sub-heading {font-size:32px !important;}
  .callout-color-bg {border-radius:0px};
  .vs-section .vs-section-content .vs-title {font-size:2em;margin-bottom:50px;}
  .vs-section .vs-section-content .vs-list ul li {font-size:1em;font-weight:600;}
  .banner-row .banner-col {height:50vh;}
  .banner-row .bottom-banner-col {background-color:#05a9ca;margin-top:0px;padding-bottom:25px;}
  .mobile-pages-banner .banner-col .container {display:block;align-items:start;}
  .banner-row .banner-col .container .banner-content-row .banner-content-col {display:block;align-items:start;margin-top:25px;}
  .mobile-pages-banner .container .banner-video-thumb {position:relative;max-width:75%;}
  .mobile-pages-banner .container .research-paper-img img {display:none;}
  .research-paper-title-col {text-align:center;}
  .banner-video-video-col {text-align:center;}
  .banner-row .banner-col .container .banner-content-row .banner-content-col .content-container h4 {font-size:35px;}
  .banner-row .banner-col .container .banner-content-row .banner-video-video-col {padding: 0px;}
}

/* sm screen size */
@media (min-width: 481px) and (max-width : 555px) {
  .partner-office {min-height:475px;} 
  .contact-form-container {padding:0 !important;}
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  /* Styles */
  .contact-form-container {padding:0 !important;}
  .partner-details-container .partner-image-div {height:325px;width:275px;}
}
